<template>
  <div class="flex">
    <button
      class="focus:outline-none hover:bg-blue-400 transition bg-blue-500 text-white text-2xl font-bold py-4 px-8 rounded shadow"
      @click.stop="toggleDatePicker"
    >
      Datum auswählen
    </button>
    <h2 class="text-2xl align-center content-center ml-4">Daten für: {{ selectedDate.toLocaleDateString() }}</h2>
    <div v-if="showDatePicker" ref="datepicker" class="datepicker shadow absolute">
      <vue2-datepicker
        @click="toggleDatePicker"
        v-model="selectedDate"
        :type="datepickerType"
        @change="onDateChange"
        :disabledDate="disableDates"
        :format="datepickerFormat"
        class="p-2 text-xl border rounded mt-16"
        :lang="lang"
        :show-week-number="false"
      />
    </div>
  </div>
</template>

<script>
  import Datepicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import de from 'vue2-datepicker/locale/de';

  export default {
    components: {
      'vue2-datepicker': Datepicker,
    },
    props: {
      value: {
        type: [String, Date],
        default: null,
      },
      timeframe: {
        type: String,
        default: 'day',
      },
    },
    data() {
      return {
        selectedDate: this.value ? new Date(this.value) : new Date(),
        showDatePicker: false,
        datepickerType: 'date',
        datepickerFormat: 'YYYY-MM-DD',
        lang: de,
        
      };
    },
    watch: {
      timeframe: function(newVal, oldVal){
          this.updateDatepickerType(oldVal);
      },
      selectedDate(newValue) {
        this.$emit('input', newValue);
      },
    },
    methods: {
      toggleDatePicker() {
        this.showDatePicker = !this.showDatePicker;
      },
      closeDatePicker() {
        this.showDatePicker = false;
      },
      updateDatepickerType(timeframe) {
        const endpointMap = {
          quarterHour: { type: 'date', format: 'YYYY-MM-DD' },
          day: { type: 'date', format: 'YYYY-MM-DD' },
          hour: { type: 'datetime', format: 'YYYY-MM-DD' },
          week: { type: 'week', format: 'YYYY-ww' },
          month: { type: 'month', format: 'YYYY-MM' }, 
          year: { type: 'year', format: 'YYYY' },
          years: { type: 'year', format: 'YYYY' },
        };
        const settings = endpointMap[timeframe] || { type: 'date', format: 'YYYY-MM-DD' };

        this.datepickerType = settings.type;
        this.datepickerFormat = settings.format;
      },
      onDateChange(date) {
        this.selectedDate = date;
        this.closeDatePicker();
      },
      disableDates(date) {
        let today = new Date
        if (date > today){
          return true;
        }
        if (this.datepickerType === 'week') {
          return date.getDay() !== 1;
        }
        return false;
      },
    },
    mounted() {
      this.updateDatepickerType(this.timeframe);
    },
  };
</script>

<style scoped>
.datepicker {
  position: absolute;
  z-index: 1000;
}
</style>
