import { render, staticRenderFns } from "./YearChart.vue?vue&type=template&id=5aee56d0"
import script from "./YearChart.vue?vue&type=script&lang=js"
export * from "./YearChart.vue?vue&type=script&lang=js"
import style0 from "./YearChart.vue?vue&type=style&index=0&id=5aee56d0&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports