<template>
    <div class="stats flex mt-20">
        <div class="filters">
            <div class="antialiased">
                <div class="flex justify-center">
                    <nav id="nav" class="container relative">
                    <span
                        class="absolute spani w-full rounded-lg shadow ease-out transition-transform transition-medium" style="background-color:#F8F8F8"
                        :style="{ transform: `translateY(calc(100% * ${selected}))` }"
                    ></span>
                    <ul class="relative">
                        <li>
                        <button
                            type="button"
                            @click="select(0)"
                            :aria-selected="selected === 0"
                            class="py-5 px-10 w-full flex items-center focus:outline-none focus-visible:underline"
                        >
                            <span
                            :class="selected === 0 ? 'text-blue-600' : 'text-gray-700'"
                            class="ml-2 text-3xl font-medium transition-all ease-out transition-medium"
                            >
                            Gelöste Tickets pro Tag
                            </span>
                        </button>
                        </li>
                        <li>
                        <button
                            type="button"
                            @click="select(1)"
                            :aria-selected="selected === 1"
                            class="py-5 px-10 w-full flex items-center focus:outline-none focus-visible:underline"
                        >
                            
                            <span
                            :class="selected === 1 ? 'text-blue-600' : 'text-gray-700'"
                            class="ml-2 text-3xl font-medium transition-all ease-out transition-medium"
                            >
                            Gelöste Tickets pro Stunde
                            </span>
                        </button>
                        </li>
                        <li>
                        <button
                            type="button"
                            @click="select(2)"
                            :aria-selected="selected === 2"
                            class="py-5 px-10 w-full flex items-center focus:outline-none focus-visible:underline"
                        >
                            
                            <span
                            :class="selected === 2 ? 'text-blue-600' : 'text-gray-700'"
                            class="ml-2 text-3xl font-medium transition-all ease-out transition-medium"
                            >
                            Gelöste Tickets pro Woche
                            </span>
                        </button>
                        </li>
                        <li>
                        <button
                            type="button"
                            @click="select(3)"
                            :aria-selected="selected === 3"
                            class="py-5 px-10 w-full flex items-center focus:outline-none focus-visible:underline"
                        >
                            
                            <span
                            :class="selected === 3 ? 'text-blue-600' : 'text-gray-700'"
                            class="ml-2 text-3xl font-medium transition-all ease-out transition-medium"
                            >
                            Gelöste Tickets pro Monat
                            </span>
                        </button>
                        </li>
                        <li>
                        <button
                            type="button"
                            @click="select(4)"
                            :aria-selected="selected === 4"
                            class="py-5 px-10 w-full flex items-center focus:outline-none focus-visible:underline"
                        >
                            
                            <span
                            :class="selected === 4 ? 'text-blue-600' : 'text-gray-700'"
                            class="ml-2 text-3xl font-medium transition-all ease-out transition-medium"
                            >
                            Gelöste Tickets dieses Jahr
                            </span>
                        </button>
                        </li>
                        <li>
                        <button
                            type="button"
                            @click="select(5)"
                            :aria-selected="selected === 5"
                            class="py-5 px-10 w-full flex items-center focus:outline-none focus-visible:underline"
                        >
                            
                            <span
                            :class="selected === 5 ? 'text-blue-600' : 'text-gray-700'"
                            class="ml-2 text-3xl font-medium transition-all ease-out transition-medium"
                            >
                            Gelöste Tickets pro Jahr
                            </span>
                        </button>
                        </li>
                        <li>
                        <button
                            type="button"
                            @click="select(6)"
                            :aria-selected="selected === 6"
                            class="py-5 px-10 w-full flex items-center focus:outline-none focus-visible:underline"
                        >
                            
                            <span
                            :class="selected === 6 ? 'text-blue-600' : 'text-gray-700'"
                            class="ml-2 text-3xl font-medium transition-all ease-out transition-medium"
                            >
                            Gelöste Tickets pro 15 Min
                            </span>
                        </button>
                        </li>
                    </ul>
                    </nav>
                </div>
            </div>
        </div>
        <div class="chart relative flex-1 px-60 pb-48">
            <Loading v-if="loading" :class="[loading ? 'absolute top-1/2 left-1/2 transform-translate-x-2/4' : '']"></Loading>
            <transition name="fadepage" mode="out-in">
                <TodayChart v-if="selected == 0"/>
                <HourChart v-if="selected == 1"/>
                <WeekChart v-if="selected == 2"/>
                <MonthChart v-if="selected == 3"/>
                <YearChart v-if="selected == 4"/>
                <DecadeChart v-if="selected == 5"/>
                <QuarterHourChart v-if="selected == 6"/>
            </transition>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .bar {
        fill: steelblue;
    }

    .bar:hover {
        fill: brown;
    }

    .axis--x path {
        display: none;
    }

    // .container {
    //     width: 100%;
    // }
    .spani {
        height: 47px;
    }
    .ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
    }
    .ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
    .ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }

    .transition-fastest {
    transition-duration: 75ms;
    }
    .transition-faster {
    transition-duration: 100ms;
    }
    .transition-fast {
    transition-duration: 150ms;
    }
    .transition-medium {
    transition-duration: 200ms;
    }
    .transition-slow {
    transition-duration: 300ms;
    }
    .transition-slower {
    transition-duration: 500ms;
    }
    .transition-slowest {
    transition-duration: 700ms;
    }

    .transition-all {
    transition-property: all;
    }
    .transition-opacity {
    transition-property: opacity;
    }
    .transition-transform {
    transition-property: transform;
    }

    .focus-visible.focus-visible\:underline {
    text-decoration: underline;
    }

    @media screen and (max-width: 576px) {
        .stats {
            flex-direction: column;
        }
        .chart {
            padding: 0px;
            margin-top: 30px;
        }
        .spani {
            height: 27px;
        }
    }
</style>

<script>
import TodayChart from '@/components/charts/TodayChart.vue';
import HourChart from '@/components/charts/HourChart.vue';
import WeekChart from '@/components/charts/WeekChart.vue';
import MonthChart from '@/components/charts/MonthChart.vue';
import YearChart from '@/components/charts/YearChart.vue';
import DecadeChart from '@/components/charts/DecadeChart.vue';
import QuarterHourChart from '@/components/charts/QuarterHourChart.vue';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            selected: 0
        }
    },
    components: {
        TodayChart,
        HourChart,
        WeekChart,
        MonthChart,
        YearChart,
        DecadeChart,
        QuarterHourChart
    },
    methods: {
        select(i) {
           this.selected = i;
        },
    },
    computed: {
        ...mapGetters(['loading'])
    }
}
</script>